import React from 'react'
import { navigate } from 'gatsby'
import logoSmall from '../images/logo-small.svg'

class OverlayNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  toggle = (e, props) => {
    e.preventDefault()
    this.setState({ isOpen: !this.state.isOpen })
  }

  navigateTo = (e, props, path) => {
    this.toggle(e, props)
    navigate(path)
  }

  render() {
    const items = [
      { key: 'home', title: 'HOME', path: '/#home' },
      { key: 'profile', title: 'PROFILE', path: '/#profile' },
      { key: 'whatido', title: 'WHAT I DO', path: '/#whatido' },
      { key: 'work', title: 'WORK', path: '/#work' },
      { key: 'presenter', title: 'PRESENTER', path: '/#presenter' },
      { key: 'blog', title: 'BLOG', path: '/#blog' },
      { key: 'contact', title: 'CONTACT', path: '/#contact' },
    ]
    return (
      <div className="toggler-root fadein-header">
        <div className="container is-fluid toggler-button-container">
          <button
            className="toggler-button"
            onClick={e => this.toggle(e, this.props)}
          >
            <div className={this.state.isOpen ? 'toggler is-open' : 'toggler'}>
              <span />
              <span />
            </div>
          </button>
        </div>
        <div
          className={
            this.state.isOpen ? 'overlay-nav-root is-open' : 'overlay-nav-root'
          }
        >
          <div>
            <figure className="image has-image-centered is-48x48">
              <img src={logoSmall} alt="SmallLogo" />
            </figure>
          </div>
          <ul>
            {items.map(item => {
              return (
                <li
                  key={item.key}
                  className={this.state.isOpen ? 'is-open ' + item.key : null}
                >
                  <a
                    href="#"
                    onClick={e => this.navigateTo(e, this.props, item.path)}
                    className="title has-text-white"
                  >
                    {item.title}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default OverlayNav
