import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import OverlayNav from './overlay-nav'

import './styles/index.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
            url
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
            { property: 'og:title', content: data.site.siteMetadata.title },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: data.site.siteMetadata.url },
            {
              property: 'og:description',
              content: data.site.siteMetadata.description,
            },
            {
              property: 'og:image',
              content: '/metadata/ogp.png',
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },
          ]}
          link={[
            { rel: 'icon', href: '/favicon.ico', type: 'image/x-icon' },
            {
              rel: 'apple-touch-icon',
              sizes: '180x180',
              href: '/metadata/apple-touch-icon.png',
            },
          ]}
        >
          <html lang="en" />
          <script>{`
          (function(d) {
            var config = {
              kitId: 'afg0oiz',
              scriptTimeout: 3000,
              async: true
            },
            h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
          })(document);
        `}</script>
        </Helmet>
        <div style={{ overflow: 'hidden' }}>
          {children}
          <OverlayNav />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
